import React from "react";
import GoogleIcon from "../../assets/icons/google.svg";
import { oauth2SignIn } from "../../../services/Services";

const GoogleComponent = () => {

  const handleAuth = () => {
    oauth2SignIn();
  };

  return (
    <button
      type="button"
      className="row m-auto app-login-button-google"
      onClick={handleAuth}
    >
      <div className="col-1">
        <img src={GoogleIcon} alt="google-logo" width="16px" height="16px" />
      </div>
      <div className="col-11">Continuar con Google</div>
    </button>
  );
};

export default GoogleComponent;
