import React, { useEffect } from "react";
import HomeHeader from "../../../assets/images/home_header.webp";
import VerificationPasswordComponent from "../../../components/auth/verification-password/VerificationPasswordComponent";
import "./VerificationPassword.css";
import { useNavigate } from "react-router-dom";

const VerificationPassword = () => {
  const navigate = useNavigate();

  useEffect(() => {

    const token = sessionStorage.getItem("access_token");

    if (!token) {
      navigate(-1);
    }

    return () => {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-forget">
      <div className="app-forget-header">
        <div>
          <img src={HomeHeader} alt="logo" width="100%" height="auto" />
        </div>
      </div>
      <div className="app-forget-container">
        <VerificationPasswordComponent />
      </div>
    </div>
  );
};

export default VerificationPassword;
