import React, { useContext } from "react";
import ActionsContext from "../../context/ActionsContext";
import "./Dialog.css";

const DialogComponent = () => {
  const { dialog } = useContext(ActionsContext);

  const handleClose = () => {
    dialog.handleDialog();
  };

  return (
    <div
      className="app-dialog-container"
      style={{ height: dialog.show ? "auto" : "0" }}
    >
      <div className="app-dialog-btn-close">
        <button type="button" onClick={handleClose}></button>
      </div>
      <div className="app-dialog-form">
        {dialog.children}
      </div>
    </div>
  );
};

export default DialogComponent;
