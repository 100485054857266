import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import ChevronLeft from "../../assets/icons/chevron-left.svg";
import Card1 from "../../assets/images/giftcards/card1.svg";
import Card2 from "../../assets/images/giftcards/card2.svg";
import Card3 from "../../assets/images/giftcards/card3.svg";
import * as yup from "yup";
import { getProfile } from "../../../services/Services";

const GiftCardFormComponent = (props) => {
  const navigate = useNavigate();
  const { cardId } = useParams();
  const cards = [Card1, Card2, Card3];

  useEffect(() => {

    const token = sessionStorage.getItem("access_token");

    getProfile(token)
      .then((response) => {
        if (response.status === 200) {
          formik.setFieldValue('name', response.data.name)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const formik = useFormik({
    initialValues: {
      monto: "0",
      name: "Jorge Martín Duarte López",
      email: "",
      terminos: false,
    },
    validationSchema: yup.object({
      monto: yup.number().moreThan(0).required("Seleccione un monto"),
      name: yup.string("Ingrese el nombre").required(),
      email: yup
        .string()
        .email("La dirección de correo ingresada no es validad")
        .required(),
      terminos: yup.boolean().default(() => false),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <div className="app-giftcards">
      <div className="row app-giftcards-header">
        <div className="col-2 col-md-2" onClick={handleBack}>
          <img
            src={ChevronLeft}
            alt={"chevron left"}
            width="16px"
            height="16px"
          />
        </div>
        <div className="col-10 col-md-10">
          <span>PERSONALIZAR</span>
          <span>GIFT CARD</span>
        </div>
      </div>
      <div className="app-giftcards-container">
        <div className="app-giftcards-card">
          <span className="app-gifcards-card-name">{formik.values.name}</span>
          <img
            src={cards[cardId - 1]}
            alt="card1"
            width="150px"
            height="81.55px"
          />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-floating">
            <select
              className="form-select"
              id="monto"
              name="monto"
              value={formik.values.monto}
              onChange={formik.handleChange}
              placeholder="Seleccione el monto"
            >
              <option value="0">Seleccione el monto</option>
              <option value="200">$ 200.00 MXN</option>
            </select>
            <label htmlFor="monto">Seleccione el monto</label>
          </div>
          <div className="form-floating">
            <input
              className="form-control"
              type="text"
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder="Para"
            />
            <label htmlFor="name">Para</label>
          </div>
          <div className="form-floating">
            <input
              className="form-control"
              type="text"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              placeholder="Correo electrónico"
            />
            <label htmlFor="name">Correo electrónico</label>
          </div>
          <div className="app-gifcards-info mb-4">
            <span className="col">
              <i className="bi bi-info-circle"></i>
            </span>
            <span className="col">Esta compra no es facturable</span>
          </div>
          <div className="form-check mb-4">
            <input
              className="form-check-input"
              type="checkbox"
              id="terminos"
              name="terminos"
              value={formik.values.terminos}
              onChange={formik.handleChange}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Acepto los <strong>Términos y condiciones</strong> y confirmo que
              he leído el <strong>Aviso de Privacidad </strong>
            </label>
          </div>

          <div className="mb-5">
            <button className="app-giftcards-button" type="submit">
              Confirmar compra
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GiftCardFormComponent;
