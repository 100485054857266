import { useFormik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import { postChangePassword } from "../../../../services/Services";
import { useNavigate } from "react-router-dom";

const ChangePasswordComponent = () => {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [success, setSuccess] = useState("");
  const [message, setMessage] = useState("");

  const formik = useFormik({
    initialValues: { password: "", password_confirmation: "" },
    validationSchema: yup.object({
      password: yup
        .string("Contraseña")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "8 caracteres - 1 símbolo - 1 mayúscula"
        )
        .required("La contraseña es requerida."),
      password_confirmation: yup
        .string("Confirmar contraseña")
        .equals([yup.ref("password")], "La contraseña no coincide.")
        .required("La confirmación de la contraseña es requerida."),
    }),
    onSubmit: (values) => {
      setIsSubmit(true);

      const token = sessionStorage.getItem("access_token");

      postChangePassword(token, values)
        .then((response) => {
          if (response.status === 200) {
            setSuccess("Contraseña actualizada correctamente");

            setMessage("");

            sessionStorage.clear();

            setTimeout(() => {
              setIsSubmit(false);

              navigate("/login");
            }, 1500);
          }
        })
        .catch(() => {
          setIsSubmit(false);
          setMessage("Error al cambiar la contraseña");
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="app-forget-label m-auto">
        <span>
          Ingresa tu nueva contraseña de acceso en los siguientes campos de
          entrada.
        </span>
      </div>
      <div className="form-floating mb-2">
        <input
          type="password"
          className={`form-control${
            formik.errors.password ? " is-invalid" : ""
          }`}
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          placeholder="Contraseña"
          autoComplete="off"
          required
        />
        <label htmlFor="password">Contraseña</label>
      </div>
      <div className="app-login-form-info">
        <span> 8 caracteres - 1 símbolo - 1 mayúscula</span>
      </div>
      <div className="form-floating mb-2">
        <input
          type="password"
          className={`form-control${
            formik.errors.password_confirmation ? " is-invalid" : ""
          }`}
          id="password_confirmation"
          name="password_confirmation"
          value={formik.values.password_confirmation}
          onChange={formik.handleChange}
          placeholder="Confirma tu contraseña"
          autoComplete="off"
          required
        />
        <label htmlFor="password">Confirma tu contraseña</label>
      </div>
      {message !== "" && (
        <div className="row m-auto mt-1 app-login-error">{message}</div>
      )}
      {success !== "" && (
        <div className="row m-auto mt-1 app-login-success">{success}</div>
      )}
      <div className="row">
        <div className="col-12">
          <button
            type="submit"
            className="app-forget-button"
            disabled={isSubmit}
          >
            Cambiar Constraseña
          </button>
        </div>
      </div>
    </form>
  );
};

export default ChangePasswordComponent;
