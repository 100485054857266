import React, { useEffect, useState } from "react";
import OnboardingComponent from "../../components/onboarding/OnboardingComponent";
import HeaderLogo from "../../assets/images/onboarding_header.webp";
import { NavLink, Outlet } from "react-router-dom";
import "./Login.css";

const Login = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return show ? (
    <OnboardingComponent setShow={setShow} />
  ) : (
    <div className="app-login">
      <div className="app-login-header">
        <img
          className="img img-fluid"
          src={HeaderLogo}
          alt="header"
          width="100%"
          height="auto"
        />
      </div>
      <div className="app-login-container">
        <div className="app-login-shadow"></div>
      </div>
      <div className="app-login-body">
        <div className="app-login-options">
          <div className="col">
            <NavLink className="nav-link login" to="/">
              Iniciar sesión
            </NavLink>
          </div>
          <div className="col vdivider">
            <span></span>
          </div>
          <div className="col">
            <NavLink className="nav-link" to="signup">
              Registrarse
            </NavLink>
          </div>
        </div>
        <div className="app-login-outlet">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Login;
