import React from "react";
import Logo from "../../assets/images/logo.svg";
import AccounCircle from "../../assets/icons/account_circle.svg";
import Tucane from "../../assets/icons/tucane.svg";
import ShoppingBag from "../../assets/icons/shopping_bag.svg";
import BookMark from "../../assets/icons/book_mark.svg";
import ShieldPrivate from "../../assets/icons/shield-private.svg";
import Logout from "../../assets/icons/logout.svg";
import { NavLink, useNavigate } from "react-router-dom";
import "./Menu.css";

const MenuComponent = ({ handleMenu }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <div className="app-menu-container">
      <div className="app-menu-header">
        <img src={Logo} alt="" width="auto" height="auto" />
      </div>
      <div className="app-menu-items">
        <NavLink className="app-menu-link" to="/profile" onClick={handleMenu}>
          <div className="app-menu-item">
            <span className="app-menu-item-icon">
              <img
                src={AccounCircle}
                alt="account_circle"
                width="24px"
                height="24px"
              />
            </span>
            <span className="app-menu-item-label">PERFIL</span>
          </div>
        </NavLink>
        <NavLink className="app-menu-link" to="/about" onClick={handleMenu}>
          <div className="app-menu-item">
            <span className="app-menu-item-icon">
              <img src={Tucane} alt="tucane" width="24px" height="24px" />
            </span>
            <span className="app-menu-item-label">SOBRE TUCANÊ</span>
          </div>
        </NavLink>
        <NavLink className="app-menu-link" to="/shopping" onClick={handleMenu}>
          <div className="app-menu-item">
            <span className="app-menu-item-icon">
              <img
                src={ShoppingBag}
                alt="shopping bag"
                width="24px"
                height="24px"
              />
            </span>
            <span className="app-menu-item-label">MIS COMPRAS</span>
          </div>
        </NavLink>
        <NavLink
          className="app-menu-link"
          to="/terms-and-conditions"
          onClick={handleMenu}
        >
          <div className="app-menu-item">
            <span className="app-menu-item-icon">
              <img src={BookMark} alt="bookmark" width="24px" height="24px" />
            </span>
            <span className="app-menu-item-label">TERMINOS Y CONDICIONES</span>
          </div>
        </NavLink>
        <NavLink
          className="app-menu-link"
          to="/privacy-notice"
          onClick={handleMenu}
        >
          <div className="app-menu-item">
            <span className="app-menu-item-icon">
              <img
                src={ShieldPrivate}
                alt="shield private"
                width="24px"
                height="24px"
              />
            </span>
            <span className="app-menu-item-label">AVISO DE PRIVACIDAD</span>
          </div>
        </NavLink>
        <div className="app-menu-item" onClick={handleLogout}>
          <span className="app-menu-item-icon">
            <img src={Logout} alt="logout" width="24px" height="24px" />
          </span>
          <span className="app-menu-item-label">CERRAR SESIÓN</span>
        </div>
        <div className="app-menu-close">
          <button className="app-menu-close-btn" onClick={handleMenu}>
            <i className="bi bi-x-lg"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MenuComponent;
