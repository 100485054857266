import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { postProfileChangePassword } from "../../../../services/Services";

const ChangePassword = (props) => {
  const { dialog } = props;
  const [isSubmit, setIsSubmit] = useState(false);
  const [message, setMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      current_password: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: yup.object({
      current_password: yup.string("Contraseña").nullable(),
      password: yup
        .string("Contraseña")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "8 caracteres - 1 símbolo - 1 mayúscula"
        )
        .required("La contraseña es requerida."),
      password_confirmation: yup
        .string("Confirmar contraseña")
        .equals([yup.ref("password")], "La contraseña no coincide.")
        .required("La confirmación contraseña es requerida."),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      setIsSubmit(true);
      const token = sessionStorage.getItem("access_token");

      postProfileChangePassword(token, values)
        .then((response) => {
          if (response.status === 200) {
            sessionStorage.setItem("access_token", response.data.access_token);
            setIsSubmit(false);
            dialog.handleDialog();
          }
        })
        .catch((error) => {
          setIsSubmit(false);
          setMessage("Error al cambiar la contraseña")
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="app-form-title">CAMBIAR CONTRASEÑA</div>
      <div className="app-form-floating">
        <label className="app-form-label" htmlFor="current_password">
          <input
            className={`app-form-control form-control ${
              formik.errors.current_password ? " is-invalid" : ""
            }`}
            type="password"
            name="current_password"
            id="current_password"
            value={formik.values.current_password}
            onChange={formik.handleChange}
            autoComplete="off"
          />
          <span>Contraseña actual</span>
        </label>
      </div>
      <div className="app-form-divider">
        <span></span>
      </div>
      <div className="app-form-floating mb-2">
        <label className="app-form-label" htmlFor="password">
          <input
            className={`app-form-control form-control ${
              formik.errors.password ? " is-invalid" : ""
            }`}
            type="password"
            name="password"
            id="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            autoComplete="off"
          />
          <span>Nueva contraseña</span>
        </label>
      </div>
      <div className="app-login-form-info text-dark">
        <span> 8 caracteres - 1 símbolo - 1 mayúscula</span>
      </div>
      <div className="app-form-floating">
        <label className="app-form-label" htmlFor="password_confirmation">
          <input
            className={`app-form-control form-control ${
              formik.errors.password_confirmation ? " is-invalid" : ""
            }`}
            type="password"
            name="password_confirmation"
            id="password_confirmation"
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
            autoComplete="off"
          />
          <span>Confirmar contraseña</span>
        </label>
      </div>
      {message !== "" && (
        <div className="row m-auto mt-1 app-login-error">{message}</div>
      )}
      <div className="app-form-btn mt-5 mb-3">
        <button type="submit" disabled={isSubmit}>Cambiar Contraseña</button>
      </div>
    </form>
  );
};

export default ChangePassword;
