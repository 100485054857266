import { useFormik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import { postRecoverPassword } from "../../../../services/Services";
import { useNavigate } from "react-router-dom";

const RecoverPasswordComponent = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: { username: "" },
    validationSchema: yup.object({
      username: yup
        .string("Correo electrónico")
        .email("El correo es requerido."),
    }),
    onSubmit: (values) => {
      postRecoverPassword(values)
        .then((response) => {
          if (response.status === 200) {
            sessionStorage.setItem("access_token", response.data.access_token);
            navigate(`/verification-password/${values.username}`);
            setIsSubmit(false);
          }
        })
        .catch(() => {
          setMessage("Error al recuperar la contraseña");
          setIsSubmit(false);
        });
    },
  });


  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="app-forget-label m-auto">
        <span>
          Para recuperar tu contraseña ingresa tu correo electrónico en el
          siguiente campo de entrada.
        </span>
      </div>
      <div className="form-floating mb-2">
        <input
          type="email"
          className={`form-control${
            formik.errors.username ? " is-invalid" : ""
          }`}
          id="username"
          name="username"
          value={formik.values.username}
          onChange={formik.handleChange}
          placeholder="Ingresa tu correo electrónico"
          autoComplete="off"
          required
        />
        <label htmlFor="username">Ingresa tu correo electrónico</label>
      </div>
      {message !== "" && (
        <div className="row m-auto mt-1 app-login-error">{message}</div>
      )}
      <div className="row">
        <div className="col-12">
          <button
            type="submit"
            className="app-forget-button"
            disabled={isSubmit}
          >
            Recuperar Constraseña
          </button>
        </div>
      </div>
    </form>
  );
};

export default RecoverPasswordComponent;
