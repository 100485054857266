import React from "react";
import Tucane from "../../assets/icons/tucane-dark.svg";

const PrivacyComponent = () => {
  return (
    <div>
      <div className="app-privacy-container-icon">
        <img src={Tucane} alt="logo" width="29px" height="16px" />
      </div>
      <div className="app-privacy-paragraph-title">

      </div>
      <div className="app-privacy-paragraph">

      </div>
      <div className="app-privacy-paragraph-title">

      </div>
      <div className="app-privacy-paragraph">

      </div>
    </div>
  );
};

export default PrivacyComponent;
