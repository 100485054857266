import React from "react";
import { useNavigate } from "react-router-dom";
import ChevronLeft from "../../assets/icons/chevron-left.svg";
import PrivacyComponent from "../../components/privacy/PrivacyComponent";
import "./Privacy.css";

const Privacy = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="app-terms">
      <div className="app-terms-header">
        <span className="app-terms-header-icon" onClick={handleBack}>
          <img
            src={ChevronLeft}
            alt={"chevron left"}
            width="16px"
            height="16px"
          />
        </span>
        <span className="app-terms-header-label">AVISO DE PRIVACIDAD</span>
      </div>
      <div className="app-terms-container">
        <PrivacyComponent />
      </div>
    </div>
  );
};

export default Privacy;
