import QueryString from "qs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { postOauth } from "../../../../services/Services";

const OAuthSignin = () => {
  const navigate = useNavigate();
  const hash = window.location.hash;
  const data = QueryString.parse(hash);

  useEffect(() => {

    delete data["#state"];

    postOauth({
      ...data,
      state: { p: parseInt(process.env.REACT_APP_ODOO_ID_GOOGLE_PROVIDER) },
    })
      .then((response) => {
        if (response.status === 200) {
          sessionStorage.setItem("access_token", response.data.access_token);
          navigate("/home");
        }
      })
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default OAuthSignin;
