import axios from "axios";

const api_url = process.env.REACT_APP_API;

const FormData = require("form-data");

export async function postSignIn(data) {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${api_url}/auth/signin`,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(data),
  };

  return await axios.request(config);
}

export async function postOauth(data) {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${api_url}/auth_oauth/signin`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  return await axios.request(config);
}

export async function postSignUp(data) {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${api_url}/auth/signup`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  return axios.request(config);
}

export async function postVerifyEmail(token, data) {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${api_url}/auth/verify_email`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  return axios.request(config);
}

export async function postResendCode(token) {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${api_url}/auth/resend_code`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return axios.request(config);
}

export async function postRecoverPassword(data) {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${api_url}/auth/recover_password`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios.request(config);
}

export async function postVerifyPassword(token, data) {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${api_url}/auth/verify_password`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  return axios.request(config);
}

export async function postChangePassword(token, data) {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${api_url}/auth/change_password`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  return axios.request(config);
}

export async function getProfile(token) {
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${api_url}/profile`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return axios.request(config);
}

export async function getCountries(token) {
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${api_url}/catalogs/countries`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return axios.request(config);
}

export async function getStates(token, id) {
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${api_url}/catalogs/countries/${id}/states`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return axios.request(config);
}

export async function putProfileChangePhoto(token, photo) {
  const data = new FormData();
  data.append("upload_file", photo);

  const config = {
    method: "put",
    maxBodyLength: Infinity,
    url: `${api_url}/profile/change_photo`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  return axios.request(config);
}

export async function putProfile(token, data) {
  const config = {
    method: "put",
    maxBodyLength: Infinity,
    url: `${api_url}/profile`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data)
  };

  return axios.request(config);
}

export async function postProfileChangePassword(token, data) {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${api_url}/profile/change_password`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  return axios.request(config);
}

export async function oauth2SignIn() {
  // Google's OAuth 2.0 endpoint for requesting an access token
  const oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

  // Create element to open OAuth 2.0 endpoint in new window.
  const form = document.createElement("form");
  form.setAttribute("method", "GET"); // Send as a GET request.
  form.setAttribute("action", oauth2Endpoint);

  // Parameters to pass to OAuth 2.0 endpoint.
  const params = {
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: `${window.location.protocol}//${window.location.host}/auth_oauth/signin`,
    scope: "https://www.googleapis.com/auth/drive.metadata.readonly",
    state: "oauth",
    include_granted_scopes: "true",
    response_type: "token",
  };

  // Add form parameters as hidden input values.
  for (var p in params) {
    var input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", p);
    input.setAttribute("value", params[p]);
    form.appendChild(input);
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form);
  form.submit();
}

export async function getShopping(token) {
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${api_url}/shopping`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return axios.request(config);
}
