import React, { useEffect, useState } from "react";
import Dayjs from "dayjs";
import { getShopping } from "../../../../services/Services";
import "dayjs/locale/es-mx";
import "./Historial.css";

const HistorialComponent = () => {

  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const token = sessionStorage.getItem("access_token");

    getShopping(token)
      .then((response) => {
        if (response.status === 200) {
          setTransactions(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {};

  }, []);

  return (
    <div className="app-historial">
      <div className="app-form-floating">
        <label className="app-form-label" htmlFor="historial">
          <select
            className="app-form-select"
            id="historial"
            name="historial"
            defaultValue="1"
            aria-label="Floating label select example"
          >
            <option value="0">Seleccione una opción</option>
            <option value="1">Todas</option>
          </select>
          <span>Mostrar</span>
        </label>
      </div>
      {transactions?.map((transaction, index) => (
        <div key={index}>
          <div className="app-historial-date">
            {Dayjs(transaction?.date).locale("es-mx").format("DD ")}de
            {Dayjs(transaction?.date).locale("es-mx").format(" MMMM YYYY")}
          </div>
          <ul className="app-historial-list">
            {transaction?.items?.map((item, index) => (
              <li className="app-historial-list-item" key={index}>
                <div className="col">
                  <span>{item?.name}</span>
                  <span>Talla {item?.size}</span>
                </div>
                <div className="col">
                  <span className={`${item?.type}`}>
                    {item?.type === "del" ? "Utilizaste " : "+"}
                    {item?.points} Pts
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default HistorialComponent;
