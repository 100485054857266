import { useFormik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { postSignIn } from "../../../services/Services";

const LoginComponent = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const element = document.querySelector("a.nav-link.login");

  useEffect(() => {
    element?.classList?.add("active");

    return () => {
      element?.classList?.remove("active");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      username:
        process.env.NODE_ENV === "development"
          ? "jorge.duarte@hg-consulting.mx"
          : "",
      password: process.env.NODE_ENV === "development" ? "RtFI!kmT5yPR&1S" : "",
    },
    validationSchema: yup.object({
      username: yup
        .string("Correo electrónico")
        .email("El correo es requerido.")
        .required("El correo es requerido"),
      password: yup
        .string("Contraseña")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "8 caracteres - 1 símbolo - 1 mayúscula"
        )
        .required("La contraseña es requerida."),
    }),
    onSubmit: async (values) => {
      setIsSubmit(true);
      await postSignIn(values)
        .then((response) => {
          if (response.status === 200) {
            sessionStorage.setItem("access_token", response.data.access_token);
            setIsSubmit(false);
            navigate("/home");
          }
        })
        .catch(() => {
          setMessage("Usuario o Contraseña Incorrecta.");
          setIsSubmit(false);
        });
    },
  });

  return (
    <form style={{ margin: "auto 5%" }} onSubmit={formik.handleSubmit}>
      <div className="form-floating mb-2">
        <input
          className={`form-control${
            formik.errors.username ? " is-invalid" : ""
          }`}
          id="username"
          name="username"
          type="email"
          value={formik.values.username}
          onChange={formik.handleChange}
          placeholder="Ingresa tu correo electrónico"
          autoComplete="off"
        />
        <label htmlFor="username">Ingresa tu correo electrónico</label>
      </div>
      <div className="form-floating mb-2">
        <input
          type="password"
          className={`form-control${
            formik.errors.password ? " is-invalid" : ""
          }`}
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          placeholder="Contraseña"
          aria-errormessage={formik.errors.password}
          autoComplete="off"
        />
        <label htmlFor="password">Contraseña</label>
      </div>
      <div className="app-login-form-info">
        <span> 8 caracteres - 1 símbolo - 1 mayúscula</span>
      </div>
      {message !== "" && (
        <div className="row m-auto mt-1 app-login-error">{message}</div>
      )}
      <div className="row m-auto">
        <button type="submit" className="app-form-button" disabled={isSubmit}>
          Entrar
        </button>
      </div>
      <div className="app-form-forget-password">
        <NavLink className="nav-link" to="/recover-password">
          ¿Olvidaste tu contraseña?
        </NavLink>
      </div>
    </form>
  );
};

export default LoginComponent;
