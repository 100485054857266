import { useFormik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postSignUp } from "../../../services/Services";

const RegisterComponent = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      username: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: yup.object({
      name: yup.string("Ingresa tu nombre").required("El nombre es requerido."),
      username: yup
        .string("Correo electrónico")
        .email("El correo es requerido."),
      password: yup
        .string("Contraseña")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "8 caracteres - 1 símbolo - 1 mayúscula"
        ),
      password_confirmation: yup
        .string("Confirmar contraseña")
        .equals([yup.ref("password")], "La contraseña no coincide."),
    }),
    onSubmit: (values) => {
      setIsSubmit(true);
      postSignUp(values)
        .then((response) => {
          if (response.status === 201) {
            sessionStorage.setItem("access_token", response.data.access_token);
            navigate(`/verification-email/${values.username}`);
          }
        })
        .catch(() => {
          setMessage(
            "El usuario ya se encuentra registrado por favor ingrese con su usuario y contraseña"
          );
          setIsSubmit(false);
        });
    },
  });

  return (
    <form style={{ margin: "auto 5%" }} onSubmit={formik.handleSubmit}>
      <div className="form-floating mb-2">
        <input
          type="text"
          className={`form-control${formik.errors.name ? " is-invalid" : ""}`}
          id="name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          placeholder="Ingresa tu nombre completo"
          autoComplete="off"
          required
        />
        <label htmlFor="name">Ingresa tu nombre completo</label>
      </div>

      <div className="form-floating mb-2">
        <input
          type="email"
          className={`form-control${
            formik.errors.username ? " is-invalid" : ""
          }`}
          id="username"
          name="username"
          value={formik.values.username}
          onChange={formik.handleChange}
          placeholder="Ingresa tu correo electrónico"
          autoComplete="off"
          required
        />
        <label htmlFor="username">Ingresa tu correo electrónico</label>
      </div>

      <div className="form-floating mb-2">
        <input
          type="password"
          className={`form-control${
            formik.errors.password ? " is-invalid" : ""
          }`}
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          placeholder="Contraseña"
          autoComplete="off"
          required
        />
        <label htmlFor="password">Contraseña</label>
      </div>

      <div className="form-floating mb-2">
        <input
          type="password"
          className={`form-control${
            formik.errors.password_confirmation ? " is-invalid" : ""
          }`}
          id="password_confirmation"
          name="password_confirmation"
          value={formik.values.password_confirmation}
          onChange={formik.handleChange}
          placeholder="Confirma tu contraseña"
          autoComplete="off"
          required
        />
        <label htmlFor="password">Confirma tu contraseña</label>
      </div>

      <div className="app-login-form-info m-auto">
        <span> 8 caracteres - 1 símbolo - 1 mayúscula</span>
      </div>
      {message !== "" && (
        <div className="row m-auto mt-1 app-login-error">{message}</div>
      )}
      <div className="row m-auto">
        <button type="submit" className="app-form-button" disabled={isSubmit}>
          Registrarse
        </button>
      </div>
    </form>
  );
};

export default RegisterComponent;
