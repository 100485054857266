import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HomeHeader from "../../../assets/images/home_header.webp";
import VerificationEmailComponent from "../../../components/auth/verification-email/VerificationEmailComponent";
import "./VerificationEmail.css";

const VerificationEmail = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem("access_token");

    if (!token) {
      navigate(-1);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-verify">
      <div className="app-verify-header">
        <div>
          <img src={HomeHeader} alt="logo" width="100%" height="auto" />
        </div>
      </div>
      <div className="app-verify-container">
        <VerificationEmailComponent />
      </div>
    </div>
  );
};

export default VerificationEmail;
