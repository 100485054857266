import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getCountries } from "../../../services/Services";
import { useNavigate } from "react-router-dom";

const SelectCountriesComponent = (props) => {
  const [countries, setCountries] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {

    const token = sessionStorage.getItem("access_token");

    getCountries(token)
      .then((response) => {
        if (response.status === 200) {
          setCountries(response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/");
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <select
      className={props.className}
      id={props.id}
      name={props.name}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      readOnly={props.readOnly}
      disabled={props.disabled}
      autoComplete={props.autoComplete}
    >
      <option value="0">seleccione una opción</option>
      {countries.map((item, index) => (
        <option value={item.id} key={index}>{item.name}</option>
      ))}
    </select>
  );
};

export default SelectCountriesComponent;

SelectCountriesComponent.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  autoComplete: PropTypes.string.isRequired,
};
