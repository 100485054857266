import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
/*---------------------------COMPONENTS---------------------------------*/
import Login from "./app/pages/login/Login";
import MenuComponent from "./app/components/login/MenuComponent";
import RegisterComponent from "./app/components/login/RegisterComponent";
import LoginComponent from "./app/components/login/LoginComponent";
import Home from "./app/pages/home/Home";
import Wallet from "./app/pages/wallet/Wallet";
import News from "./app/pages/news/News";
import Promotions from "./app/pages/promotions/Promotions";
import Events from "./app/pages/events/Events";
import GiftCards from "./app/pages/giftcards/GiftCards";
import ListGiftCardsComponent from "./app/components/giftcards/ListGiftCardsComponent";
import GiftCardFormComponent from "./app/components/giftcards/GiftCardFormComponent";
import Achievements from "./app/pages/achievements/Achievements";
import Scanner from "./app/pages/scanner/Scanner";
import Profile from "./app/pages/auth/profile/Profile";
/*------------------------BOOTSTRAP----------------------------------*/
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import About from "./app/pages/about/About";
import Terms from "./app/pages/terms/Terms";
import Shopping from "./app/pages/shopping/Shopping";
import VerificationEmail from "./app/pages/auth/verification-email/VerificationEmail";
import OAuthSignin from "./app/pages/auth/oauth/OAuthSignin";
import RequestCodePassword from "./app/pages/auth/recover-password/RecoverPassword";
import VerificationPassword from "./app/pages/auth/verification-password/VerificationPassword";
import ChangePassword from "./app/pages/auth/change-password/ChangePassword";
import Privacy from "./app/pages/privacy/Privacy";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}>
          <Route index element={<MenuComponent />}></Route>
          <Route path="login" element={<LoginComponent />}></Route>
          <Route path="signup" element={<RegisterComponent />}></Route>
        </Route>
        <Route path="/auth_oauth/signin" element={<OAuthSignin />}></Route>
        <Route path="/recover-password" element={<RequestCodePassword />}></Route>
        <Route path="/verification-password/:email" element={<VerificationPassword />}></Route>
        <Route path="/change-password" element={<ChangePassword />}></Route>
        <Route path="/verification-email/:email" element={<VerificationEmail />}></Route>
        <Route path="/home" element={<App />}>
          <Route index element={<Home />}></Route>
          <Route path="wallet" element={<Wallet />}></Route>
          <Route path="news" element={<News />}></Route>
          <Route path="promotions" element={<Promotions />}></Route>
          <Route path="events" element={<Events />}></Route>
          <Route path="gifcards" element={<GiftCards />}>
            <Route index element={<ListGiftCardsComponent />}></Route>
            <Route path=":cardId" element={<GiftCardFormComponent />}></Route>
          </Route>
        </Route>
        <Route path="/achievements" element={<App />}>
          <Route index element={<Achievements />} />
        </Route>
        <Route path="/scan" element={<App />}>
          <Route index element={<Scanner />} />
        </Route>
        <Route path="/profile" element={<App />}>
          <Route index element={<Profile />} />
        </Route>
        <Route path="/about" element={<App />}>
          <Route index element={<About />} />
        </Route>
        <Route path="/shopping" element={<App />}>
          <Route index element={<Shopping />} />
        </Route>
        <Route path="/terms-and-conditions" element={<App />}>
          <Route index element={<Terms />} />
        </Route>
        <Route path="/privacy-notice" element={<App />}>
          <Route index element={<Privacy />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
