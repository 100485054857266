import React, { useEffect, useState } from "react";
import db from "../../../database/db";
import Card from "../../home/card/Card";
import "./Cards.css";

const CardsComponent = () => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    setCards(db.cards);

    return () => {};
  }, []);

  return cards.map((item, index) => <Card key={index} {...item} />);
};

export default CardsComponent;
