import { useFormik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  postResendCode,
  postVerifyPassword,
} from "../../../../services/Services";

const VerificationPasswordComponent = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { email } = useParams();

  const formik = useFormik({
    initialValues: { codigo1: "", codigo2: "", codigo3: "", codigo4: "" },
    validationSchema: yup.object({
      codigo1: yup.number().min(1).max(9).required(),
      codigo2: yup.number().min(0).max(9).required(),
      codigo3: yup.number().min(0).max(9).required(),
      codigo4: yup.number().min(0).max(9).required(),
    }),
    onSubmit: ({ codigo1, codigo2, codigo3, codigo4 }) => {
      const token = sessionStorage.getItem("access_token");
      const data = { code: [codigo1, codigo2, codigo3, codigo4].join("") };

      postVerifyPassword(token, data).then((response) => {
        if (response.status === 202) {
          sessionStorage.setItem("access_token", response.data.access_token);
          navigate("/change-password");
        }
      });
    },
  });

  const handleResendCode = async () => {
    setIsSubmit(true);
    const token = sessionStorage.getItem("access_token");
    await postResendCode(token)
      .then((response) => {
        if (response.status === 200) {
          sessionStorage.setItem("access_token", response.data.access_token);
          setIsSubmit(false);
        }
      })
      .catch(() => {
        setIsSubmit(false);
        setMessage("Error al Reenviar Código.");
      });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="app-forget-label m-auto">
        <span>
          Enviamos un PIN de recuperación de tu contraseña a tu correo
          registrado.
        </span>
      </div>
      <div className="app-forget-label">
        <span>{email}</span>
      </div>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          id="codigo1"
          name="codigo1"
          minLength={1}
          maxLength={1}
          value={formik.values.codigo1}
          onChange={formik.handleChange}
          aria-label="Example text with two button addons"
        />
        <input
          type="text"
          className="form-control"
          id="codigo2"
          name="codigo2"
          minLength={1}
          maxLength={1}
          value={formik.values.codigo2}
          onChange={formik.handleChange}
          aria-label="Example text with two button addons"
        />
        <input
          type="text"
          className="form-control"
          id="codigo3"
          name="codigo3"
          minLength={1}
          maxLength={1}
          value={formik.values.codigo3}
          onChange={formik.handleChange}
          aria-label="Example text with two button addons"
        />
        <input
          type="text"
          className="form-control"
          id="codigo4"
          name="codigo4"
          minLength={1}
          maxLength={1}
          value={formik.values.codigo4}
          onChange={formik.handleChange}
          aria-label="Example text with two button addons"
        />
      </div>
      {message !== "" && (
        <div className="row m-auto mt-1 app-login-error">{message}</div>
      )}
      <div className="row">
        <div className="col-12">
          <button
            type="submit"
            className="app-forget-button"
            disabled={isSubmit}
          >
            Verificar PIN
          </button>
        </div>
      </div>
      <div className="app-forget-info">
        <span>¿No recibiste el PIN?</span>
      </div>
      <div className="row">
        <div className="col-12">
          <button
            type="submit"
            className="app-forget-button"
            onClick={handleResendCode}
            disabled={isSubmit}
          >
            Reenviar PIN
          </button>
        </div>
      </div>
    </form>
  );
};

export default VerificationPasswordComponent;
