import React from "react";
import { useNavigate } from "react-router-dom";
import HomeHeader from "../../../assets/images/home_header.webp";
import ChevronLeft from "../../../assets/icons/chevron-left.svg";
import RecoverPasswordComponent from "../../../components/auth/recover-password/RecoverPasswordComponent";
import "./RecoverPassword.css";

const RequestCodePassword = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="app-forget">
      <div className="app-forget-header">
        <div onClick={handleBack}>
          <img
            src={ChevronLeft}
            alt={"chevron left"}
            width="16px"
            height="16px"
          />
        </div>
        <div>
          <img src={HomeHeader} alt="logo" width="100%" height="auto" />
        </div>
      </div>
      <div className="app-forget-container">
        <RecoverPasswordComponent />
      </div>
    </div>
  );
};

export default RequestCodePassword;
