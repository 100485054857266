import React, { useEffect, useState } from "react";
import CardsComponent from "../../components/home/card/CardsComponent";
import BenefitsComponent from "../../components/home/benefits/BenefitsComponent";
import CarouselComponent from "../../components/home/carousel/CarouselComponent";
import GifCardComponent from "../../components/home/gifcard/GifCardComponent";
import ShareComponent from "../../components/home/share/ShareComponent";
import StoreComponent from "../../components/home/store/StoreComponent";
import "./Home.css";
import { getProfile } from "../../../services/Services";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [name, setName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem("access_token");

    getProfile(token)
      .then((response) => {
        if (response.status === 200) {
          setName(response.data.name);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/");
        }
      });

    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="app-home-welcome">¡Hola, {name}!</div>
      <div className="app-home-cards">
        <CardsComponent />
      </div>
      <div className="app-home-carousel">
        <CarouselComponent />
      </div>
      <div className="app-home-store-title">TIENDA</div>
      <div className="app-home-store">
        <StoreComponent />
      </div>
      <div className="app-home-benefits">
        <BenefitsComponent />
      </div>
      <div className="app-home-share">
        <ShareComponent />
      </div>
      <div className="app-home-gifcard">
        <GifCardComponent />
      </div>
    </div>
  );
};

export default Home;
