import { useFormik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import { postResendCode, postVerifyEmail } from "../../../../services/Services";
import { useNavigate, useParams } from "react-router-dom";

const VerificationEmailComponent = () => {
  const navigate = useNavigate();
  const { email } = useParams();
  const [isSubmit, setIsSubmit] = useState(false);
  const [message, setMessage] = useState("");

  const formik = useFormik({
    initialValues: { codigo1: "", codigo2: "", codigo3: "", codigo4: "" },
    validationSchema: yup.object({
      codigo1: yup.number().min(1).max(9).required(),
      codigo2: yup.number().min(0).max(9).required(),
      codigo3: yup.number().min(0).max(9).required(),
      codigo4: yup.number().min(0).max(9).required(),
    }),
    onSubmit: ({ codigo1, codigo2, codigo3, codigo4 }) => {
      setIsSubmit(true);
      const token = sessionStorage.getItem("access_token");
      const data = { code: [codigo1, codigo2, codigo3, codigo4].join("") };

      postVerifyEmail(token, data)
        .then((response) => {
          if (response.status === 202) {
            sessionStorage.setItem("access_token", response.data.access_token);
            setIsSubmit(false);
            navigate("/home");
          }
        })
        .catch(() => {
          setIsSubmit(false);
          setMessage("Código Invalido.");
        });
    },
  });

  const handleResendCode = async () => {
    setIsSubmit(true);
    const token = sessionStorage.getItem("access_token");
    await postResendCode(token)
      .then((response) => {
        if (response.status === 200) {
          sessionStorage.setItem("access_token", response.data.access_token);
          setIsSubmit(false);
        }
      })
      .catch(() => {
        setIsSubmit(false);
        setMessage("Error al Reenviar Código.");
      });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="app-verify-label m-auto">
        <span>Enviamos un código de verificación a:</span>
      </div>
      <div className="app-verify-label">
        <span>
          <strong>{email}</strong>
        </span>
      </div>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          id="codigo1"
          name="codigo1"
          minLength={1}
          maxLength={1}
          value={formik.values.codigo1}
          onChange={formik.handleChange}
          aria-label="Example text with two button addons"
        />
        <input
          type="text"
          className="form-control"
          id="codigo2"
          name="codigo2"
          minLength={1}
          maxLength={1}
          value={formik.values.codigo2}
          onChange={formik.handleChange}
          aria-label="Example text with two button addons"
        />
        <input
          type="text"
          className="form-control"
          id="codigo3"
          name="codigo3"
          minLength={1}
          maxLength={1}
          value={formik.values.codigo3}
          onChange={formik.handleChange}
          aria-label="Example text with two button addons"
        />
        <input
          type="text"
          className="form-control"
          id="codigo4"
          name="codigo4"
          minLength={1}
          maxLength={1}
          value={formik.values.codigo4}
          onChange={formik.handleChange}
          aria-label="Example text with two button addons"
        />
      </div>
      {message !== "" && (
        <div className="row m-auto app-login-error">{message}</div>
      )}
      <div className="app-verify-info mt-1">
        <span>¿No recibiste el código? Revisa tu carpeta de spam.</span>
      </div>
      <div className="row">
        <div className="col-12">
          <button
            type="submit"
            className="app-verify-button"
            disabled={isSubmit}
          >
            Verificar Código
          </button>
          <button
            type="button"
            className="app-resend-button"
            onClick={handleResendCode}
            disabled={isSubmit}
          >
            Reenviar código
          </button>
        </div>
      </div>
    </form>
  );
};

export default VerificationEmailComponent;
