import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import Account from "../../../assets/images/account.svg";
import Pencil from "../../../assets/icons/edit.svg";
import ActionsContext from "../../../context/ActionsContext";
import ChangePassword from "./ChangePassword";
import {
  getProfile,
  putProfile,
  putProfileChangePhoto,
} from "../../../../services/Services";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import SelectCountriesComponent from "../../selects/SelectCountriesComponent";
import SelectStatesComponent from "../../selects/SelectStatesComponent";

const ProfileComponent = () => {
  const [photo, setPhoto] = useState(null);
  const [isUpdate, setIsUpdate] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const { dialog } = useContext(ActionsContext);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      gender: 0,
      city: "",
      state_id: 0,
      country_id: 0,
      street: "",
      outdoor_number: "",
      indoor_number: "",
      colonge: "",
      zip: "",
    },
    validationSchema: yup.object({
      name: yup.string(),
      state_id: yup.number(),
      country_id: yup.number(),
      outdoor_number: yup.string(),
      indoor_number: yup.string(),
      colonge: yup.string(),
      zip: yup.string(),
    }),
    onSubmit: (values) => {
      console.log(values);
      const token = sessionStorage.getItem("access_token");

      putProfile(token, values)
        .then((response) => {
          if (response.status === 204) {
            setIsUpdate(!isUpdate);
            setIsEdit(!isEdit);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  });

  useEffect(() => {
    if (isUpdate) {
      const token = sessionStorage.getItem("access_token");

      getProfile(token)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data;

            if (data.image_1920) {
              setPhoto(`data:image/jpeg;base64,${data.image_1920}`);
              delete data.image_1920;
            }

            formik.setValues(data);
            setIsUpdate(!isUpdate);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            navigate("/");
            sessionStorage.clear();
          }
        });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate]);

  const handleChangePassword = () => {
    dialog.handleDialog(<ChangePassword dialog={dialog} />);
  };

  const handlePhoto = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/jpg,image/jpeg,image/png";
    input.click();
    input.addEventListener("change", (e) => {
      const photo = e.target.files[0];

      if (photo) {
        setPhoto(URL.createObjectURL(photo));

        const token = sessionStorage.getItem("access_token");

        putProfileChangePhoto(token, photo)
          .then((response) => {
            if (response.status === 204) {
              setIsUpdate(!isUpdate);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <div>
      <div className="app-profile-account">
        <img
          src={photo ? photo : Account}
          alt="profile"
          width="128px"
          height="128px"
        />
        {!isEdit && (
          <button className="app-profile-account-icon" onClick={handlePhoto}>
            <img src={Pencil} alt="Edit" width="16px" height="16px"></img>
          </button>
        )}
      </div>
      <form onSubmit={formik.handleSubmit} className="app-profile-form">
        <div className="app-form-floating">
          <label className="app-form-label" htmlFor="name">
            <input
              className="app-form-control"
              type="text"
              name="name"
              id="name"
              placeholder="Nombre Completo"
              value={formik.values.name}
              onChange={isEdit ? formik.handleChange : () => {}}
              readOnly={!isEdit}
              disabled={!isEdit}
              autoComplete="off"
            />
            <span>Nombre Completo</span>
          </label>
        </div>

        <div className="app-form-floating">
          <label className="app-form-label" htmlFor="name">
            <input
              className="app-form-control"
              type="email"
              name="email"
              id="email"
              placeholder="Correo electrónico"
              value={formik.values.email}
              readOnly={true}
              disabled={true}
              autoComplete="off"
            />
            <span>Correo electrónico</span>
          </label>
        </div>

        <div className="app-form-floating">
          <label className="app-form-label" htmlFor="gender">
            <select
              className="app-form-select"
              id="gender"
              name="gender"
              placeholder="seleccione una opción"
              value={formik.values.gender}
              onChange={isEdit ? formik.handleChange : () => {}}
              readOnly={!isEdit}
              disabled={!isEdit}
              autoComplete="off"
            >
              <option value="0">seleccione una opción</option>
              <option value="1">Masculino</option>
              <option value="2">Femenino</option>
            </select>
            <span>Género</span>
          </label>
        </div>

        <div className="app-form-divider">
          <span></span>
        </div>

        <div className="app-form-floating">
          <label className="app-form-label" htmlFor="country_id">
            <SelectCountriesComponent
              className="app-form-select"
              id="country_id"
              name="country_id"
              placeholder="seleccione una opción"
              value={formik.values.country_id}
              onChange={isEdit ? formik.handleChange : () => {}}
              readOnly={!isEdit}
              disabled={!isEdit}
              autoComplete="off"
            />
            <span>Ciudad</span>
          </label>
        </div>

        <div className="app-form-floating">
          <label className="app-form-label" htmlFor="state_id">
            <SelectStatesComponent
              className="app-form-select"
              id="state_id"
              name="state_id"
              placeholder="seleccione una opción"
              value={formik.values.state_id}
              country_id={formik.values.country_id}
              onChange={isEdit ? formik.handleChange : () => {}}
              readOnly={!isEdit}
              disabled={!isEdit}
              autoComplete="off"
            />
            <span>Estado</span>
          </label>
        </div>

        <div className="app-form-floating">
          <label className="app-form-label" htmlFor="city">
            <input
              className="app-form-control"
              type="text"
              name="city"
              id="city"
              placeholder="Población o Municipio"
              value={formik.values.city}
              onChange={isEdit ? formik.handleChange : () => {}}
              readOnly={!isEdit}
              disabled={!isEdit}
              autoComplete="off"
            />
            <span>Población o Municipio</span>
          </label>
        </div>

        <div className="app-form-floating">
          <label className="app-form-label" htmlFor="street">
            <input
              className="app-form-control"
              type="text"
              name="street"
              id="street"
              placeholder="Dirección"
              value={formik.values.street}
              onChange={isEdit ? formik.handleChange : () => {}}
              readOnly={!isEdit}
              disabled={!isEdit}
              autoComplete="off"
            />
            <span>Dirección</span>
          </label>
        </div>

        <div className="app-form-group">
          <div className="app-form-floating">
            <label className="app-form-label" htmlFor="outdoor_number">
              <input
                className="app-form-control"
                type="text"
                name="outdoor_number"
                id="outdoor_number"
                placeholder="No. Exterior"
                value={formik.values.outdoor_number}
                onChange={isEdit ? formik.handleChange : () => {}}
                readOnly={!isEdit}
                disabled={!isEdit}
                autoComplete="off"
              />
              <span>No. Exterior</span>
            </label>
          </div>

          <div className="app-form-floating">
            <label className="app-form-label" htmlFor="indoor_number">
              <input
                className="app-form-control"
                type="text"
                name="indoor_number"
                id="indoor_number"
                placeholder="No. Interior (Opc)"
                value={formik.values.indoor_number}
                onChange={isEdit ? formik.handleChange : () => {}}
                readOnly={!isEdit}
                disabled={!isEdit}
                autoComplete="off"
              />
              <span>No. Interior (Opc)</span>
            </label>
          </div>
        </div>

        <div className="app-form-floating">
          <label className="app-form-label" htmlFor="colonia">
            <input
              className="app-form-control"
              type="text"
              name="colonia"
              id="colonia"
              placeholder="Colonia"
              value={formik.values.colonia}
              onChange={isEdit ? formik.handleChange : () => {}}
              readOnly={!isEdit}
              disabled={!isEdit}
              autoComplete="off"
            />
            <span>Colonia</span>
          </label>
        </div>

        <div className="app-form-floating">
          <label className="app-form-label" htmlFor="zip">
            <input
              className="app-form-control"
              type="text"
              name="zip"
              id="zip"
              placeholder="Código Postal"
              value={formik.values.zip}
              onChange={isEdit ? formik.handleChange : () => {}}
              readOnly={!isEdit}
              disabled={!isEdit}
              autoComplete="off"
            />
            <span>Código Postal</span>
          </label>
        </div>

        <div className="app-form-divider">
          <span></span>
        </div>

        <div className="app-form-btn-outline mb-1">
          <button type="button" onClick={isEdit ? handleSubmit : handleEdit}>
            {isEdit ? "Guardar Cambios" : "Editar Perfil"}
          </button>
        </div>

        {isEdit && <div className="app-form-btn-outline mb-1">
          <button type="button" onClick={handleEdit}>
            Cancelar Cambios
          </button>
        </div>}

        {!isEdit && (
          <div className="app-form-btn-outline">
            <button type="button" onClick={handleChangePassword}>
              Cambiar Contraseña
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default ProfileComponent;
